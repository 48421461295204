/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-debugger */
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable eqeqeq */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-irregular-whitespace */
/*---------------------------------------------------------------------------------------------
 *  Copyright (c) Microsoft Corporation. All rights reserved.
 *  Licensed under the MIT License. See License.txt in the project root for license information.
 *--------------------------------------------------------------------------------------------*/ //
import { IModuleStateProps, withModuleState } from '@msdyn365-commerce-modules/checkout-utilities';
import { Button,Heading,IModuleProps, INodeProps, Waiting } from '@msdyn365-commerce-modules/utilities';
import { AsyncResultStatus, getUrlSync } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { computed, when } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ICcbcucheckoutData } from './ccbcucheckout.data';
import { ICcbcucheckoutProps} from './ccbcucheckout.props.autogenerated';
import AlertComponent from './components/alert';
import { getLineItems, ILineItems } from './components/get-line-items';
import { getOrderSummary, IOrderSummary } from './components/get-order-summary';
import GuidedFormComponent from './components/guided-form';
import PlaceOrderButtonComponent from './components/place-order-button';
import placeOrder from './methods/place-order';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import GetStudentDetailsComponent from './components/getStudentDetails';
import GetTimeSlotsComponent from './components/get-time-slots';
export * from './components/get-line-items';
export * from './components/get-order-summary';
import PostStudentDetailsComponent from './components/postStudentDetails';

type DeviceType = 'Mobile';
interface ICheckoutState {
    errorMessage: string;
    selectedStudent: string;
    isStudentSelected: boolean;
    event_ID: string;
    postStudentObj: any;
    isSlotSelected: boolean;
    selectedSlotID: string;
    selectedTime: string;    
    isNotApplicableChecked: boolean;
    isPlaceOrderStateLoading: boolean;
}
export interface ICheckoutViewProps extends ICcbcucheckoutProps<ICcbcucheckoutData> {
    className: string;
    canShow?: boolean;
    isEmptyCart?: boolean;
    isConsentGiven?: boolean;
    hasError?: boolean;
    cartStatus?: string;
    isMobile?: boolean;
    isEditorialMode?: boolean;
    backToShoppingUrl?: string;
    editCartLink?: string;
    hasSalesOrder: boolean;
    checkoutProps: IModuleProps;
    headerProps: INodeProps;
    bodyProps: INodeProps;
    mainProps: INodeProps;
    mainControlProps: INodeProps;
    sideProps: INodeProps;
    sideControlFirstProps: INodeProps;
    sideControlSecondProps: INodeProps;
    guidedForm?: React.ReactNode;
    title?: React.ReactNode;
    loading?: React.ReactNode;
    alert?: React.ReactNode;
    orderSummary?: IOrderSummary;
    lineItems?: ILineItems;
    placeOrderButton?: React.ReactNode;
    keepShoppingButton?: React.ReactNode;
    orderConfirmation?: React.ReactNode;
    studentValue?: React.ReactNode;
    postStudent?: React.ReactNode;
    timeSlots?: React.ReactNode;
    isSlotSeletionRequired: boolean;
    isStudentFeatureRequired: boolean;
    isPlaceOrderStateLoading:boolean;
}
export interface IUpdateStudentDetailsProps {
    EMAIL: string;
    NAME: string;
    EVENT_GUID: string;
    RETAIL_CHANNEL_ID: string;
    displayNameError: boolean;
    displayEmailError: boolean;
    displayBtn: boolean;
}
export interface IPostStudent {
    ID: string;
}
export interface ICheckoutModuleProps extends ICcbcucheckoutProps<ICcbcucheckoutData>, IModuleStateProps { }
/**
 *
 * CheckoutContainer component
 * @extends {React.Component<ICheckoutModuleProps>}
 */
// @ts-ignore
@withModuleState
@observer
class Checkout extends React.Component<ICheckoutModuleProps> {

    public state: ICheckoutState = {
        errorMessage: '',
        selectedStudent: '',
        isStudentSelected: false,
        event_ID: "",
        postStudentObj: "",
        isSlotSelected: false,
        selectedSlotID: "",
        selectedTime: "",
        isNotApplicableChecked: false,
        isPlaceOrderStateLoading: false
   };
   
    @computed get cartStatus(): AsyncResultStatus {
        return get(this.props, 'data.checkout.status');
    }
    @computed get isEmptyCart(): boolean {
        if (this.isEditorialMode) {
            // Editorial mode: Ignore empty cart
            return false;
        }
        const cart = get(this.props, 'data.checkout.result.checkoutCart.cart');
        return this.cartStatus === 'SUCCESS' && !!cart && !isEmpty(cart) && (!cart.CartLines || cart.CartLines.length === 0);
    }
    @computed get hasError(): boolean {
        const cart = get(this.props, 'data.checkout.result.checkoutCart.cart');
        return this.cartStatus === 'FAILED' || (this.cartStatus === 'SUCCESS' && (!cart || isEmpty(cart)));
    }
    @computed get isEditorialMode(): boolean {
        return get(this.props, 'context.request.params.isEditor');
    }
    @computed get canPlaceOrder(): boolean {
        return this.props.moduleState.isReady;
    }
    public componentDidMount(): void {
        const {
            resources: { genericErrorMessage }
        } = this.props;
        // @ts-ignore: Compiler not reconizing condition check for function params
        when(
            () => this.hasError,
            () => {
                this.setState({
                    errorMessage: genericErrorMessage
                });
                this.props.context.telemetry.error('Failed to get cart');
            }
        );
        // @ts-ignore: Compiler not reconizing condition check for function params
        when(
             () => {
                 return this.isEmptyCart;
             },
             () => {
                 const editCartLink = getUrlSync('cart', this.props.context.actionContext) || '';
                 if (window && editCartLink) {
                      // Redirect to cart page if cart is empty
                      window.location.assign(editCartLink);
                  } else {
                      this.setState({
                          errorMessage: genericErrorMessage
                      });
                      this.props.context.telemetry.error('Cart is empty, but no editCartLink found');
                  }
             }
         );
    }
    // tslint:disable-next-line:max-func-body-length
    public render(): JSX.Element {
        const {
            moduleState,
            config: { className, checkoutHeading, disableGuidedCheckoutFlow },
            resources,
            data: {
                students: {
                    result
                },
                timeslots
            }
        } = this.props;

        const { errorMessage } = this.state;
        const { backToShopping, placeOrderText, cookieConsentRequiredMessage, genericErrorMessage } = resources;
        const checkoutClass = classnames('ms-checkout', className);
        const checkoutInformation = this.getSlotItems('checkoutInformation');
        const isConsentGiven =
            this.props.context.request &&
            this.props.context.request.cookies &&
            this.props.context.request.cookies.isConsentGiven &&
            this.props.context.request.cookies.isConsentGiven();
        const isMobile =
            this.props.context.request &&
            this.props.context.request.device &&
            (this.props.context.request.device.Type as DeviceType) === 'Mobile';
        const backToShoppingUrl = this.props.context.app.config.backToShoppingCustomURL? this.props.context.app.config.backToShoppingCustomURL : '';
        const editCartLink = getUrlSync('cart', this.props.context.actionContext) || '';
        const termsOfUse = this.props.app.config.termsOfUse;
        const privacyPolicy = this.props.app.config.privacyPolicy;
        const defaultDropDownOption = this.props.app.config.studentDropdownDefaultText; 
        const isStudentFeatureRequired = this.props.context.app.config.isStudentFeatureRequired;
        const isSlotSelectionRequired = this.props.context.app.config.isSlotSelectionFeatureRequired;
        let selectedLocalStorageStudName : any; //= localStorage.getItem("studentName") || "";
        let selectedLocalStorageStudID : any; //= localStorage.getItem("studentID") || "";
        if(localStorage != undefined || localStorage != "")
        {
if(localStorage.getItem("studentName") && localStorage.getItem("studentID") && localStorage.getItem("ValidStudent") == "true")
{
    selectedLocalStorageStudName = localStorage.getItem("studentName");
    selectedLocalStorageStudID = localStorage.getItem("studentID");
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.isStudentSelected = true;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.selectedStudent = selectedLocalStorageStudID;
    
}
        }
        let viewProps: ICheckoutViewProps = {
            ...this.props,
            className: checkoutClass,
            isEmptyCart: this.isEmptyCart,
            isConsentGiven,
            hasError: this.hasError,
            hasSalesOrder: this.props.data.checkout && this.props.data.checkout.result !== undefined && this.props.data.checkout.result.salesOrder !== undefined,
            cartStatus: this.cartStatus,
            isMobile,
            backToShoppingUrl,
            editCartLink,
            isEditorialMode: this.isEditorialMode,
            orderConfirmation: this.props.slots.orderConfirmation && this.props.slots.orderConfirmation.length > 0 ? this.props.slots.orderConfirmation[0] : undefined,
            checkoutProps: { moduleProps: this.props, className: checkoutClass },
            headerProps: { className: 'ms-checkout__head' },
            bodyProps: { className: 'ms-checkout__body' },
            mainProps: { className: 'ms-checkout__main' },
            mainControlProps: { className: 'ms-checkout__main-control' },
            sideProps: { className: 'ms-checkout__side' },
            sideControlFirstProps: { className: 'ms-checkout__side-control-first' },
            sideControlSecondProps: { className: 'ms-checkout__side-control-second' },
            title: checkoutHeading && <Heading {...checkoutHeading} className='ms-checkout__title' />,
            isStudentFeatureRequired: isStudentFeatureRequired,
            isSlotSeletionRequired: isSlotSelectionRequired,
            studentValue: ( 
                <GetStudentDetailsComponent {...{
                        placeOrderText: defaultDropDownOption, 
                        selectStudent: this.selectStudent, 
                        studentsArr: result,
                        isStudentSelected: this.state.isStudentSelected,
                        canPlaceOrder: this.canPlaceOrder,
                        selectedStudentName: selectedLocalStorageStudName || "",
                        selectedStudent: selectedLocalStorageStudID || "",
                    }
                } />
            ),
            postStudent: (
                <PostStudentDetailsComponent {...{
                        isVisible: (this.state.selectedStudent == "Other")? true : false,
                        EVENT_GUID: this.state.event_ID,
                        sendStudentPostDetails: (obj: IUpdateStudentDetailsProps, displayBtn: boolean)=> {
                            var newObj = obj;
                            newObj["EVENT_GUID"] = this.state.event_ID;
                                this.setState({
                                    postStudentObj: newObj,
                                    isStudentSelected: true
                                });
                        }
                    }}                
                />
            ),
            timeSlots: (
                <GetTimeSlotsComponent {...{
                    slots: timeslots.result,
                    slotSelection: this.selectTimeSlot,
                    enabled:true,
                    isSelected: false,
                    isNotApplicableChecked: this.state.isNotApplicableChecked,
                    checkBoxNA: this.selectCheckBoxNA,
                    chkboxLbl: this.props.context.app.config.timeSlotNAChkbox
                }} />
            ),
            isPlaceOrderStateLoading: this.state.isPlaceOrderStateLoading
       };
        const preOrderExpUrl = getUrlSync('preorderexp', this.props.context.actionContext) || '';
        if (!preOrderExpUrl) {
            throw new Error('Error: No orderConfirmationUrl');
        }
        if (isConsentGiven === false) {
            viewProps = {
                ...viewProps,
                alert: <AlertComponent {...{ message: cookieConsentRequiredMessage }} />
            };
        } else if (!this.cartStatus || this.cartStatus === 'LOADING' || this.isEmptyCart) {
            viewProps = {
                ...viewProps,
                loading: <Waiting className='msc-waiting-circular msc-waiting-lg'/>
            };
        } else if (this.hasError) {
            viewProps = {
                ...viewProps,
                alert: <AlertComponent {...{ message: genericErrorMessage }} />
            };
        } else {
            viewProps = {
                ...viewProps,
                alert: errorMessage && <AlertComponent {...{ message: errorMessage }} />,
                canShow: true,
                guidedForm: checkoutInformation ? (
                    <GuidedFormComponent
                        {...{
                            items: checkoutInformation,
                            moduleState: moduleState,
                            disableGuidedCheckoutFlow: disableGuidedCheckoutFlow,
                            resource: resources,
                            isMobile: isMobile,
                            isEditor: this.isEditorialMode,
                            termsOfUse: termsOfUse,
                            privacyPolicy: privacyPolicy
                        }}
                    />
                ) : (
                        undefined
                    ),
                orderSummary: getOrderSummary(this.props),
                lineItems: getLineItems(this.props),
                placeOrderButton: (
                    <PlaceOrderButtonComponent {...{ placeOrderText, placeOrder: this.onPlaceOrder, canPlaceOrder: this.canPlaceOrder, selectedStudentVal: this.state.selectedStudent, isStudentSelected: (!isStudentFeatureRequired || (isStudentFeatureRequired && this.state.isStudentSelected)), showBtn: (!isStudentFeatureRequired || (this.state.postStudentObj["displayBtn"] && isStudentFeatureRequired)), isSlotSelected: (!isSlotSelectionRequired || (this.state.isSlotSelected && isSlotSelectionRequired)), setLoading: (loading:boolean) => this.setState({isPlaceOrderStateLoading: loading}) }} />
                ),
                keepShoppingButton: backToShoppingUrl && (
                    <Button
                        className='ms-checkout__btn-keep-shopping msc-btn'
                        title={backToShopping}
                        color='secondary'
                        href={backToShoppingUrl}
                    >
                    {backToShopping}
                    </Button>
                )
            };
        }
        return this.props.renderView(viewProps) as React.ReactElement;
    }
    private getSlotItems = (key: string): React.ReactNode[] | undefined => {
        const { slots } = this.props;
        return slots && slots[key] && slots[key].length ? slots[key] : undefined;
    };

    private onPlaceOrder = async (): Promise<void> => {
        const {
            resources: { genericErrorMessage },
            context: { actionContext },
            slots: { orderConfirmation }
        } = this.props;
        const hasOrderConfirmation = orderConfirmation && orderConfirmation.length > 0;
var finalSlotId;
var finalSlottimings;
        var studentID = this.state.selectedStudent;
        if(this.state.selectedStudent == "Other" && this.props.context.app.config.isStudentFeatureRequired){
            studentID = await this.updateStudentDetailsToCDS(this.state.postStudentObj);
            //this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "EventStudentName", studentID);
        }
        var selectedSlotID = this.state.selectedSlotID;

        if(this.props.context.app.config.isSlotSelectionFeatureRequired){
            selectedSlotID = await this.updateTimeSlot(this.state.selectedSlotID);
        }

        if(((studentID && this.props.context.app.config.isStudentFeatureRequired) || !this.props.context.app.config.isStudentFeatureRequired) && ((selectedSlotID && this.props.context.app.config.isSlotSelectionFeatureRequired) || !this.props.context.app.config.isSlotSelectionFeatureRequired))
        {
            //student name - update cart
            if(studentID && this.props.context.app.config.isStudentFeatureRequired){
        //this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "EventStudentName", studentID);
    }

    //time slot - update cart
    if(selectedSlotID && this.props.context.app.config.isSlotSelectionFeatureRequired){
        if(document.getElementsByClassName("notApplicableTimeSlot") && document.getElementsByClassName("notApplicableTimeSlot")[0]["checked"]){
            finalSlotId = "NA";
            finalSlottimings ="NA";
           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlot", "NA");
           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlotTimings", "NA");
        } else {
        var slots = document.getElementsByClassName("selectedSlot");
        if(slots && slots[0] && slots[0]["innerText"]){
            finalSlotId=selectedSlotID;
            finalSlottimings=slots[0]["innerText"];
           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlot", selectedSlotID);
           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlotTimings", slots[0]["innerText"]);
        }}
    }

    debugger;
    //employee event
    var eventCategory = this.props.context.app.config?.eventCategory;
    if(eventCategory == 'Employee Event')
    {
        let errorMsg : any = "";
        let isMaxDefined = true;
var checkoutEmail = this.props.context.actionContext.requestContext.user.emailAddress;//this.props.data.checkout.result?.guestCheckoutEmail;
//var endpointForQtychk = this.props.context.app.config?.getOrderQtyEmailBased;
var sellingPeriod = this.props.context.app.config?.sellingPeriodDescription;
const maxQtydefined :any = this.props.context.app.config?.maxOrderQtyDefined;
if(maxQtydefined == undefined)
{
    isMaxDefined = false;
    //errorMsg = "Max Quantity Is Not Defined, Please Contact Your System Administrator"; 
}
// eslint-disable-next-line prefer-const
let CurrenttotalItem :any = this.props.data.checkout.result?.checkoutCart?.cart?.TotalItems;//this.props.data.checkout.result?.checkoutCart.cart?.TotalItems;
const eventTitle : any = this.props.context.app.config?.eventTitle;

var arrQty: string[] = [];
var objQty:any = new Object();
objQty.EmailId = checkoutEmail? checkoutEmail: "";
objQty.SellingPeriod = sellingPeriod?sellingPeriod:"";
objQty.channelName = eventTitle?eventTitle:"";
 arrQty.push(JSON.stringify(objQty));
//if(eventCategory == 'Employee Event')

 const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: arrQty.toString()//JSON.stringify({ EmailId: checkoutEmail})
    };
   var qty= await fetch(`${this.props.context.app.config?.orderQtyBasedonemailEndPoint}`, requestOptions);
   var data = await qty.json();     
        console.log(data);
         // eslint-disable-next-line prefer-const
         let qtyResult = data.Quantity;
         // eslint-disable-next-line prefer-const
         let totalQtybeingPlaced :any = CurrenttotalItem + qtyResult;
         // eslint-disable-next-line prefer-const, radix
         let maxQtyValue :number= parseInt(maxQtydefined);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, prefer-const
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        if(totalQtybeingPlaced <= maxQtyValue)
        {
            await placeOrder(actionContext, this.props.data.checkout.result, this.props.data.products.result, !hasOrderConfirmation, this.state.selectedTime, this.props.app.config.pickupDate,"",this.props.context.app.config?.mapStudenttoD365Endpoint,finalSlotId,finalSlottimings).catch(error => {
                this.setState({
                    errorMessage: genericErrorMessage
                });
                this.props.telemetry.exception(error);
            });
        }
        else
        {
            
            let RemainingItems = maxQtyValue - qtyResult;
            if(qtyResult > maxQtyValue)
            {
                RemainingItems = 0;
            }
            
           
           if(!isMaxDefined)
           {
            errorMsg = "Max Quantity Is Not Defined, Please Contact Your System Administrator";
           }
           else
           {
            // eslint-disable-next-line prefer-const, prefer-template
            errorMsg = "Please reduce your order quantity to stay below the maximum of " +maxQtyValue+ " cases for the selling period. You have " +RemainingItems+ " cases remaining in this selling period. Thank you!";
            //"You have reached the maximum case count of" +qtyJson+ "cases allowed for your email id in the current selling period " +sellingPeriod+ "Please reduce the case count on this order and try again \n If you need more cases than allowed, we encourage you to order again in the next selling period. Looking forward to your continued participation!";
           }
            this.setState({
                errorMessage:errorMsg
            }); 
            this.props.context.telemetry.error(errorMsg);
        }
    }
else
{
    await placeOrder(actionContext, this.props.data.checkout.result, this.props.data.products.result, !hasOrderConfirmation, this.state.selectedTime, this.props.app.config.pickupDate,studentID,this.props.context.app.config?.mapStudenttoD365Endpoint,finalSlotId,finalSlottimings).catch(error => {
                    this.setState({
                        errorMessage: genericErrorMessage
                    });
                    this.props.telemetry.exception(error);
                });
}
        
    } 
    else {
            this.setState({
                errorMessage: genericErrorMessage
            });
        }
    };

    private selectTimeSlot = async(event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
        var selectedSlotID = event.target.getAttribute("data-eventid");
        var slots = document.getElementsByClassName("timeslot");
        var selectedTimeSlot = event.target.innerHTML;
        for(var i=0; i < slots.length; i++){
            if(slots[i]["className"].indexOf("selectedSlot") != -1){
                var clsName = slots[i]["className"];
                clsName = clsName.replace("selectedSlot", "");
                slots[i]["className"] = clsName;
            }
        }
        event.target.className += " selectedSlot";
        
        if(selectedSlotID) {
            this.setState({
                isSlotSelected: true,
                selectedSlotID: selectedSlotID,
                selectedTime: selectedTimeSlot
            });

            this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlot", selectedSlotID);
            this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlotTimings", selectedTimeSlot);
        } else {
            this.setState({
                isSlotSelected: false
            });
        }
    }

    private selectCheckBoxNA = async(event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
        if(event.target["checked"]){
            this.setState({
                isSlotSelected: true,
                selectedSlotID: "NA",
                selectedTime: "NA",
                isNotApplicableChecked: true
            });

           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlot", "NA");
           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "SelectedDeliverySlotTimings", "NA");
        } else {
            this.setState({
                isSlotSelected: false,
                selectedSlotID: "",
                selectedTime: "",
                isNotApplicableChecked: false
            });
        }

        var slots = document.getElementsByClassName("timeslot");
        for(var i=0; i < slots.length; i++){
            if(slots[i]["className"].indexOf("selectedSlot") != -1){
                var clsName = slots[i]["className"];
                clsName = clsName.replace("selectedSlot", "");
                slots[i]["className"] = clsName;
            }
        }
    }

    private selectStudent = async(val:string, event_guid: string,studename:string, studentValid : boolean): Promise<void> =>
{
    if(val != "" && val != "Other" && studentValid == true){
            this.setState({
                selectedStudent: val,
                isStudentSelected: true
            });
           // this._addOrUpdateCartExtension((await this.props.data.checkout).checkoutCart.cart, "EventStudentName", val);
        } else {
            this.setState({
                selectedStudent: val,
                isStudentSelected: false,
                event_ID: event_guid
            })
        }
}
    //Adding /updating the extension property on cart object.
    public _addOrUpdateCartExtension = (cart: Cart, extensionPropertyKey: string, extensionPropertyValue: string): void => {
        cart.ExtensionProperties = cart.ExtensionProperties || [];
        // tslint:disable-next-line: no-any
        //const property = cart.ExtensionProperties.find((extension: any) => extension.Key === extensionPropertyKey);
        //if (property) {
          //  property.Value = { StringValue: extensionPropertyValue };
        //} else {
            cart.ExtensionProperties.push({
                Key: extensionPropertyKey,
                Value: {
                    StringValue: extensionPropertyValue
                }
            });
       // }
    }
    async getStudentDetailsForPost(obj: IUpdateStudentDetailsProps): Promise<any> {
        this.setState({
            postStudentObj: obj,
            isStudentSelected: true
        });        
    }    
    async updateStudentDetailsToCDS(obj: IUpdateStudentDetailsProps): Promise<any>{
        const channelID = this.props.context.actionContext.requestContext.channel?.ChannelNaturalId;
        var arr: string[] = [];
        obj.RETAIL_CHANNEL_ID = channelID? channelID: "";
        arr.push(JSON.stringify(obj));
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: arr.toString()
        };

        const updateStu = await fetch(`${this.props.context.app.config.studentAPIEndPoint}?code=${this.props.context.app.config.studentAPIKey}&RetailChannelId=${channelID}`, requestOptions);
        const data = await updateStu.json();

        return data;
    }

    async updateTimeSlot(slotID: string): Promise<any> {

        const channelID = this.props.context.actionContext.requestContext.channel?.ChannelNaturalId;
        var obj = new Object();
        obj["ID"] = slotID;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(obj)
        };
        
        const updateSlot = await fetch(`${this.props.context.app.config.timeSlotAPIURL}?code=${this.props.context.app.config.timeSlotAPIKey}&RetailChannelId=${channelID}`, requestOptions);
        
        const data = await updateSlot.json();

        return data;
    }
}

export default Checkout;

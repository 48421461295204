/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
//import AddressAlertComponent from './address-alert';
export interface IPostButtonProps {
    placeOrderText: string;
    selectStudent(e: string, event_guid: string,studName:string, studentValid:boolean): Promise<void>;
    studentsArr?: IGetStudentDetailsData;
    isStudentSelected: boolean;
    canPlaceOrder: boolean;
    selectedStudent :string;
    selectedStudentName :string;
}

export interface IGetStudentDetailsData {
    studentsCollection?: IStudentDetails[];
}

export interface IStudentDetails {
    EMAIL: string;
    NAME: string;
    DEFAULT: boolean;
    ID: string;
    EVENT_GUID: string;
}

type IDropdownState = {
    /**
     * @friendlyName selectedIndex
     * @description selected index
     */
    selectedIndex: string;
    value: string;
    event_guid: string;
    itemID: string;
    isStudValid : any;
};


export class StudentDetailsClass extends React.Component<IPostButtonProps, IDropdownState>{
    //studValidation: boolean = false;
    // studValidation = false;
    constructor(props: IPostButtonProps) {
        super(props);
        this.state = {
            selectedIndex: "",
            value: props.selectedStudentName ||"",
            event_guid: "",
            itemID: props.selectedStudent || "",
            isStudValid:true
        };
    }

    componentDidMount(){

    }

    compare(a: any, b: any) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.band.toUpperCase();
        const bandB = b.band.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      }
      // eslint-disable-next-line no-irregular-whitespace
      private ValidateStudent = async(val :string, arr:any[]) : Promise<void> =>{

        // eslint-disable-next-line eqeqeq
        if(arr.some(obj => obj.label === val) || val == "")
        {
            //this.studValidation = true;
            this.setState({isStudValid : true});
            localStorage.setItem("studentName",this.state.value);
            localStorage.setItem("studentID", this.state.itemID);
            localStorage.setItem("ValidStudent","true");
            //this.setState({itemID:val});
         //alert("select valid stud");
        }
        else
        {
            this.setState({isStudValid : false});
            localStorage.setItem("ValidStudent","false");
        }
     };
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    render(): JSX.Element{
        const {placeOrderText, selectStudent, studentsArr, isStudentSelected, canPlaceOrder} = this.props;
        var validationCheck = (canPlaceOrder && !isStudentSelected);

        var arr: any = [];
   /*   var obj = new Object();
       var obj1 = new Object();
    //    // const objs = [{"id" : "121","label" : "gana"},{"id" : "122","label" : "sushma"}];
        obj["id"] = "123";//item.ID;
        obj["label"] ="gana test space"; //item.NAME;//
        obj1["id"] = "124";//item.ID;
        obj1["label"] ="sushma";
        arr.push(obj1);
        arr.push(obj);
 */

       //uncomment
      studentsArr?.studentsCollection?.forEach(item => {
            var obj = new Object();
            obj["id"] = item.ID;
            obj["label"] =item.NAME;//
            arr.push(obj);
            // eslint-disable-next-line eqeqeq
            if(this.state.event_guid == ""){
                this.setState({
                    event_guid: item.EVENT_GUID
                });
            }
        });
               return(
            <div className={`col-md-12 studentddn`}>
                <span className={`lblStudentDropdown`}>Please Select Student</span>
                <span className={`studentNotSelected`} style={{display: validationCheck? 'none' : 'none'}}></span>

                    <span className={`studentNotSelected`} style={{display: !this.state.isStudValid? 'block' : 'none'}}>Please enter a valid student</span>

                <ReactAutocomplete
                    items={arr}
                    inputProps={{ placeholder: `${placeOrderText}` }}
                    shouldItemRender={(item: any, value: string) => item?.label?.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    getItemValue={(item: any) => item.label}
                    renderItem={(item: any, highlighted: any) =>
                        (<div
                            key={item.id}
                            style={{ backgroundColor: highlighted ? '#eee' : '#fff'}} className={`studentAutoCompleteList`}
                        >
                            {item.label}
                        </div>)
                    }
                    value={this.state.value}
                    onChange={(e: any) => { this.setState({ value: e.target.value });
                        this.ValidateStudent(e.target.value, arr);
                        selectStudent(this.state.itemID, this.state.event_guid,this.state.value,this.state.isStudValid);
          // eslint-disable-next-line react/jsx-no-undef
               // alert: <AddressAlertComponent {...{ message: "Selct Valid Student" }} />
            }}
                    onSelect={( _value: string, item: any) => {
                        console.log(item); this.setState({ value: item.label, itemID: item.id}, () => {
                            this.ValidateStudent(_value,arr);
                            // eslint-disable-next-line eqeqeq
                        selectStudent(this.state.itemID, this.state.event_guid,this.state.value,this.state.isStudValid);
                        });
              }}
                />
            </div>
        );
    }
}

export default StudentDetailsClass;
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';

export interface IGetTimeSlotsData {
    timeslots: ITimeSlot[];
}

export interface ITimeSlot {
    ID: number;
    Timings: string;
    ColorCode: string;
    Enabled: boolean;
}

export interface IGetSlots{
    slots?: IGetTimeSlotsData;
    slotSelection(e: any): Promise<void>;
    enabled: boolean;
    isSelected: boolean;
    isNotApplicableChecked: boolean;
    checkBoxNA(e: any): Promise<void>;
    chkboxLbl: string;
}

export class GetTimeSlots extends React.Component<IGetSlots>{

    constructor(props: IGetSlots){
        super(props);
    }

    componentDidMount(){

    }

    render() {
        const {
            slots,
            slotSelection,
            checkBoxNA,
            isNotApplicableChecked,
            chkboxLbl
        } = this.props;

        return(
            <div>
                <div>
                    <div className={`timeSlotLbl`}>
                        Choose your pickup time  *
                    </div>
                    <div className={`notApplicableContainer`}>
                        <input type="checkbox" title="Click to select" checked={isNotApplicableChecked} className={`notApplicableTimeSlot`} onClick={e => {
                            checkBoxNA(e);
                        }}/>
                        <span>{chkboxLbl}</span>
                    </div>
                    <div style={{display: !isNotApplicableChecked? "block": "none"}}>
                        {
                            slots?.timeslots?.map(slot => {
                                return(
                                    // eslint-disable-next-line react/jsx-key
                                    <span className={`timeslot timeslot${slot.ColorCode}`} style={{display: slot.Enabled? "inline-block": "none"}} data-eventid={slot.ID} onClick={e => slotSelection(e)}>{slot.Timings}</span>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="timeSlotLegends" style={{display: !isNotApplicableChecked? "block": "none"}}>
                    <span className="timeSlotContainerLegend_green"><span className="greenLegend"></span><span className='legendText'>{'Suggested'}</span></span>
                    <span className="timeSlotContainerLegend_orange"><span className="orangeLegend"></span><span className='legendText'>{'Busy'}</span></span>
                    <span className="timeSlotContainerLegend_red"><span className="redLegend"></span><span className='legendText'>{'Crowded'}</span></span>
                </div>
            </div>
        );
    }
}

export default GetTimeSlots;
/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';

export interface IPostButtonProps {
    isVisible: boolean;
    EVENT_GUID: string;
    sendStudentPostDetails(postObj: IStudentDetailsState, displayBtn: boolean): void;
}

export interface IStudentDetailsState {
    EMAIL: string;
    NAME: string;
    EVENT_GUID: string;
    displayNameError: boolean;
    displayEmailError: boolean;
    displayBtn: boolean;
}

export class PostStudentDetails extends React.Component<IPostButtonProps, IStudentDetailsState>{
    constructor(props: IPostButtonProps){
        super(props);

        this.state = {
            EMAIL: "",
            NAME: "",
            EVENT_GUID: props.EVENT_GUID,
            displayNameError: false,
            displayEmailError: false,
            displayBtn: true
        };
        this.sendPostData = this.sendPostData.bind(this);
    }

    componentDidMount(){
    }

    sendPostData(btnDisplay: boolean, btnText: string) {
        if(this.state.NAME.trim()) {
            this.setState({
                displayNameError: false
            });
        }
        if(this.state.EMAIL.trim() && this.validateEmail(this.state.EMAIL)){
            this.setState({
                displayEmailError: false
            });
        }
        if(this.state.NAME.trim() && this.state.EMAIL.trim() && this.validateEmail(this.state.EMAIL)){
            if(btnText != "Change"){
                this.setState({
                    displayBtn: false
                });
            }
            var postObj: IStudentDetailsState = this.state;
            this.props.sendStudentPostDetails(postObj, !btnDisplay);
        } else if(this.state.NAME.trim() == "") {
            this.setState({
                displayNameError: true
            });
        } else if(this.state.EMAIL.trim() == "" || !this.validateEmail(this.state.EMAIL)){
            this.setState({
                displayEmailError: true
            });
        }
    }

    validateEmail(mail: string) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true);
        }
        return (false);
    }

    validateName(name: string) {
        if (/^[0-9a-zA-Z\_ ]+$/.test(name) || name == "")
        {
            return (true);
        }
        return (false);
    }

    render(): JSX.Element {
        const {
            isVisible
        } = this.props;

        return(
            isVisible?
            (<div className={`postStudent`}>
                <div className={`lblPostStudent`} style={{marginTop: 10}}>
                    <span>Please enter the details below</span>
                    <button className={`ms-checkout__guided-card-btn-edit msc-btn btn-link`} style={{display: !this.state.displayBtn? "block" : "none"}} onClick={() => {
                        this.setState({
                            displayBtn: true
                        });
                        this.sendPostData(true, "Change");
                    }}>Change</button>
                </div>
                <div className={`col-md-12`}>
                    <span className={`studentNotSelected`} style={{display: this.state.displayNameError? 'block' : 'none'}}>Please enter a valid name</span>
                </div>
                <div className={`studentName`}>
                    <input name="Name" value={this.state.NAME} onChange={(e) => {
                        if(this.validateName(e.target.value)){
                            this.setState({NAME: e.target.value});
                        }
                    }} placeholder="Name*" className={`msc-address-form__input msc-address-form__input-text first_Name`} type="text" disabled={!this.state.displayBtn? true: false}></input>
                </div>
                <div className={`col-md-12`}>
                    <span className={`studentNotSelected`} style={{display: this.state.displayEmailError? 'block' : 'none'}}>Please enter a valid email</span>
                </div>
                <div className={`studentEmail`} >
                    <input name="EMAIL" value={this.state.EMAIL} onChange={(e) => {
                        this.setState({EMAIL: e.target.value});
                    }} placeholder="Email Address*" className={`msc-address-form__input msc-address-form__input-text`} type="text" disabled={!this.state.displayBtn? true: false}></input>
                </div>
                <div>
                    <button className={`col-md-12 ms-checkout__btn-place-order msc-btn btn-primary student_save`}  onClick={() => {this.sendPostData(false, "");}} style={{display: this.state.displayBtn? "block" : "none"}}>Save & Continue</button>
                </div>
            </div>) :
            <></>
        );
    }
}

export default PostStudentDetails;